<template>
    <v-container fluid>
        <v-dialog
            persistent

            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"

        >
            <v-form @submit.prevent="saveTask">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card
                        flat
                        @drop.prevent="dragover = false"
                        @dragover.prevent="dragover = true"
                        @dragenter.prevent="dragover = true"
                        @dragend.prevent="dragover = false"
                    >
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>

                            <v-row class="mt-5">
                                <v-col cols="12" md="12">
                                    <v-row>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="task_type" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('task_type') }}</div>
                                                <v-select v-model="task_type"
                                                          :items="taskTypeItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('task_type')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          @change="getTaskType"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="3">
                                            <ValidationProvider ref="is_urgent" rules=""
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('urgent_task') }}</div>
                                                <v-select v-model="is_urgent" :items="urgentItems"
                                                          item-text="name" item-value="id"
                                                          :label="$t('urgent_task')"
                                                          :error="!valid"
                                                          :error-messages="errors"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="3">
                                            <ValidationProvider ref="deadline" rules="required|min:1" v-slot="{ errors, valid }">

                                                <div class="input_label">{{ $t('deadline') }}</div>
                                                <datetime-picker v-model="deadline" validator-name="deadline"
                                                                 :error="!valid"
                                                                 validator-rules="required|min:1"
                                                                 format="24hr"
                                                                 :label="$t('deadline')" :locale="lang"
                                                                 :min-date-time="$moment().format('YYYY-MM-DD')"
                                                                 :timezone="$auth.user().timezone" timeFormat="HH:mm"
                                                                 readonly
                                                                 hide-details
                                                                 :error-messages="errors"
                                                                 :disabled="loading"
                                                                 outlined
                                                                 solo
                                                                 flat
                                                                 dense
                                                                 color="primary"
                                                                 class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                                 clearable></datetime-picker>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="loading_type" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('loading_type') }}</div>
                                                <v-select v-model="loading_type"
                                                          :items="loadingTypeItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('loading_type')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="type_transport" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('type_transport') }}</div>
                                                <v-select v-model="type_transport"
                                                          :items="typeTransportItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('type_transport')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          multiple
                                                          persistent-hint
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="from_city" rules="required|min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('from_city') }}</div>
                                                <v-text-field v-model="from_city" type="text"
                                                              :error="!valid"
                                                              :label="$t('from_city')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="to_city" rules="required|min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('to_city') }}</div>
                                                <v-text-field v-model="to_city" type="text"
                                                              :error="!valid"
                                                              :label="$t('to_city')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col v-if="task_type === 3" cols="12">
                                            <ValidationProvider ref="goods" rules="required|min:1|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('goods') }}</div>
                                                <v-textarea v-model="goods" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('goods')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="weight" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('weight_kg') }}</div>
                                                <v-text-field v-model="weight" type="text"
                                                              :error="!valid"
                                                              :label="$t('weight_kg')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="task_type === 3" cols="6" class="py-0">
                                            <ValidationProvider ref="volume" rules="required|float_text|max:11"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('volume') }}</div>
                                                <v-text-field v-model="volume"
                                                              :error="!valid"
                                                              :label="$t('volume')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable
                                                ></v-text-field>


                                            </ValidationProvider>
                                        </v-col>

                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="stackable_cargo" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('stackable_cargo') }}</div>
                                                <v-select v-model="stackable_cargo" :items="urgentItems"
                                                          item-text="name" item-value="id"
                                                          :label="$t('stackable_cargo')"
                                                          :error="!valid"
                                                          :error-messages="errors"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="terms_delivery" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('terms_delivery') }}</div>
                                                <v-text-field v-model="terms_delivery" type="text"
                                                              :error="!valid"
                                                              :label="$t('terms_delivery_text')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              @input="checkTermsDelivery"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="task_type === 3 && show_place_argo_pickup" class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="place_argo_pickup" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('place_argo_pickup') }}</div>
                                                <v-text-field v-model="place_argo_pickup" type="text"
                                                              :error="!valid"
                                                              :label="$t('place_argo_pickup')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="insurance" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('insurance') }}</div>
                                                <v-select v-model="insurance" :items="insuranceItems"
                                                          item-text="name" item-value="id"
                                                          :label="$t('insurance')"
                                                          :error="!valid"
                                                          :error-messages="errors"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="task_type === 3" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="dangerous_cargo" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('dangerous_cargo') }}</div>
                                                <v-select v-model="dangerous_cargo" :items="urgentItems"
                                                          item-text="name" item-value="id"
                                                          :label="$t('dangerous_cargo')"
                                                          :error="!valid"
                                                          :error-messages="errors"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col v-if="task_type === 3 && (dangerous_cargo === 1)" class="py-0" cols="12">
                                            <ValidationProvider ref="special_text" rules="required|min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('special_text') }}</div>
                                                <v-textarea v-model="special_text" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('special_label')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col v-if="task_type !== 3" class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="title" rules="required|min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('task_name') }}</div>
                                                <v-text-field v-model="title" type="text"
                                                              :error="!valid"
                                                              :label="$t('task_name')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12">
                                            <form-dialog :ref="'form_'+form.sort" v-for="form in forms" :key="form.sort" :form="form"/>
                                        </v-col>

                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="responsible" rules="required|min:1" v-slot="{ errors, valid }">
                                                <UserSelection
                                                    multiple
                                                    v-model="responsible"
                                                    id="responsible"
                                                    :label="$t('responsible')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="task_type === 2" class="py-0" cols="12">
                                            <ValidationProvider ref="aligner" rules="required|min:1" v-slot="{ errors, valid }">
                                                <UserSelection
                                                    multiple
                                                    v-model="aligner"
                                                    id="aligner"
                                                    :label="$t('aligner')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>


                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="auditor" rules="min:1" v-slot="{ errors, valid }">

                                                <UserSelection
                                                    multiple
                                                    v-model="auditor"
                                                    id="auditor"
                                                    :label="$t('auditors')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="uved" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('uved.self') }}</div>
                                                <v-autocomplete v-model="uved" :items="uvedItems"
                                                                :error="!valid"
                                                                :search-input.sync="uvedSearching"
                                                                item-text="name" item-value="id"
                                                                :loading="loadingUveds"
                                                                :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                                :label="$t('uved.self')"
                                                                @click:clear="uvedSearching=null;uved=null;uvedItems=[];companyItems = [];company = null;"
                                                                @click="clearUveds"
                                                                @click:append="redirectToUved(uved)"
                                                                autocomplete="off"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loadingUveds"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                                clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list" @click="setCompanyUVED(item)">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="company" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('company') }}</div>
                                                <v-autocomplete v-model="company" :items="companyItems"
                                                                :error="!valid"
                                                                :search-input.sync="companySearching"
                                                                item-text="name" item-value="id"
                                                                :loading="loadingCompanies"
                                                                :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                                :label="$t('company')"
                                                                @click:clear="companyItems=[]"
                                                                @click="clearCompanies"
                                                                @click:append="redirectToCompany(company)"
                                                                autocomplete="off"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loading"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                                clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search_list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="deal" v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('deal.self') }}</div>
                                                <v-combobox v-model="deal" :items="dealItems"
                                                            :error="!valid"
                                                            :search-input.sync="dealSearching" hide-selected
                                                            item-text="name" item-value="id" :loading="loadingDeals"
                                                            :label="$t('deal.self')" @click:clear="dealItems=[]"
                                                            @click="clearDeals" @input="clearEmptyDealItems(deal)"
                                                            multiple return-object autocomplete="off"
                                                            hide-details
                                                            :error-messages="errors"
                                                            :disabled="loading"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                            clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search_list">
                                                            <span>{{ item.number + ' ' + ((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '') }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <v-chip v-if="item === Object(item)"
                                                                class="input_chip"
                                                                v-bind="attrs" :input-value="selected"
                                                                @click:close="parent.selectItem(item)"
                                                                :title="item.deal_status"
                                                                link close>
                                                            <span>{{ item.number }} {{ ((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '') }}</span>
                                                        </v-chip>
                                                    </template>
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ dealSearching ? $t('nothing_found_by', {'search': dealSearching}) : $t('nothing_found_name') }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-combobox>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="task_status" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('task_status') }}</div>
                                                <v-select v-model="task_status"
                                                          :items="task_statusItems"
                                                          prepend-icon="mdi-text-box-check-outline" :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('task_status')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="text" rules="min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('task_description') }}</div>
                                                <v-textarea v-model="text" type="text" :error-messages="errors"
                                                            :disabled="loading" :label="$t('task_description')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col class="py-0 pt-2" cols="12">
                                            <div class="input_label">{{ $t('task_documents') }}</div>
                                            <ValidationProvider ref="task_documents" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <v-file-input
                                                    :label="$t('task_documents')"
                                                    v-model="task_documents"
                                                    id="file"
                                                    multiple
                                                    @change="accompanyingDocumentsPreviewFiles"
                                                    hide-details
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                    clearable
                                                    :prepend-icon="null"
                                                >
                                                    <template v-slot:prepend-inner>
                                                        <v-icon size="18" color="primary">
                                                            mdi-paperclip
                                                        </v-icon>
                                                    </template>
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0">


                                            <v-list subheader>
                                                <v-list-item v-for="(file, index) in task_document_urls" :key="'task_document_'+index">
                                                    <v-list-item-icon>
                                                        <v-img
                                                            max-width="45"
                                                            :alt="`${file.name}`"
                                                            :src="file.url"
                                                            max-height="30"
                                                        ></v-img>
                                                        <v-icon
                                                            x-large
                                                            v-if="!mimeTypeImage(file.type)">mdi-file-document-outline mdi-24px
                                                        </v-icon>
                                                    </v-list-item-icon>

                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-size-10" v-text="file.name"></v-list-item-title>
                                                        <v-list-item-subtitle class="font-size-10" v-text="(file.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                                    </v-list-item-content>

                                                    <v-list-item-icon class="d-flex align-self-center mt-2">
                                                        <v-btn v-if="file.uuid"
                                                               icon
                                                               @click="deleteFileServer(file)"
                                                        >
                                                            <v-icon>mdi-delete-outline mdi-18px</v-icon>
                                                        </v-btn>
                                                        <v-btn v-if="!file.uuid"
                                                               icon
                                                               @click="deleteAccompanyingDocumentFile(file)"
                                                        >
                                                            <v-icon>mdi-delete-outline</v-icon>
                                                        </v-btn>

                                                    </v-list-item-icon>
                                                </v-list-item>
                                            </v-list>


                                        </v-col>


                                    </v-row>
                                </v-col>


                            </v-row>

                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
        <v-dialog
            v-model="task_documents_dialog"
            scrollable
            persistent
            max-width="500px"
            transition="dialog-bottom-transition"
            style="z-index:600 !important;"
        >
            <v-card z-index="600">
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('send_files', {'count': task_documents_preview.length}) }}</span>
                    </div>
                    <div>
                        <v-icon @click="task_documents_dialog = false;task_documents_preview=[];task_documents=[]">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text style="height: 300px;" class="pa-0">
                    <v-list subheader>
                        <v-list-item v-for="(document, index) in task_documents_preview" :key="'document_'+index">
                            <v-list-item-icon>
                                <v-img
                                    max-width="90"
                                    :alt="`${document.name}`"
                                    :src="document.url"
                                    max-height="60"
                                ></v-img>
                                <v-icon
                                    x-large
                                    v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="document.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon class="d-flex align-self-center mt-2">
                                <v-btn
                                    icon
                                    @click="deleteDocumentFile(document)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>

                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>


                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="addTaskFile">
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="dragover" z-index="204" color="#244ba8"
        >

            <v-row style="z-index: 500 !important;"
                   @dragover.prevent
                   @dragenter.prevent
                   @drop="dropFile($event)"
            >
                <v-col style="height: 400px;width:500px;"
                       class="grey darken-1"

                >
                    <v-col style=" height: 380px;width:490px; border: dashed 4px #fff;">

                        <v-card
                            height="350"
                            class="pa-2 grey darken-1 align-self-center"
                            elevation="0"


                        >
                            <v-col sm="12" class="text-center mt-10">
                                <v-icon
                                    left style="font-size: 150px"

                                    class="text-center">
                                    mdi-file-upload-outline
                                </v-icon>
                            </v-col>
                            <v-col sm="12" class="text-center font-weight-bold h5">{{ $t('drop_files_here_to_send_them') }}</v-col>


                        </v-card>


                    </v-col>
                </v-col>
            </v-row>


        </v-overlay>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import DatetimePicker from '../components/DatetimePicker.vue'
import FormDialog from '@/components/Eav/FormDialog.vue';
import UserSelection from "@/components/Form/UserSelection.vue";

export default {
    name: 'SubTaskForm',
    components: {
        UserSelection,
        ValidationProvider,
        ValidationObserver,
        DatetimePicker,
        FormDialog
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            id: null,
            number: null,
            title: null,
            text: null,
            task_statusItems: [],
            task_status: null,
            deadline: null,

            company: null,
            companyItems: [],
            companySearching: null,
            loadingCompanies: false,
            uved: null,
            uvedItems: [],
            uvedSearching: null,
            loadingUveds: false,
            deal: null,
            dealItems: [],
            dealSearching: null,
            loadingDeals: false,
            task_type: null,
            taskTypeItems: [],
            loadingTaskTypes: false,


            aligner: null,
            alignerItems: [],
            alignerSearching: null,
            loadingAligners: false,

            responsible: null,
            responsibleItems: [],
            responsibleSearching: null,
            loadingResponsibles: false,
            auditor: null,
            auditorItems: [],
            auditorSearching: null,
            loadingAuditors: false,
            task_uuid: null,
            dragover: false,
            task_documents: [],
            task_document_urls: [],
            task_documents_preview: [],
            task_documents_dialog: false,
            loadingDeal: false,
            is_urgent: null,
            urgentItems: [
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            insurance: null,
            insuranceItems: [
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            loading_type: null,
            loadingTypeItems: [],
            type_transport: null,
            typeTransportItems: [],
            from_city: null,
            to_city: null,
            special_text: null,
            dangerous_cargo: null,
            stackable_cargo: null,
            terms_delivery: null,
            place_argo_pickup: null,
            show_place_argo_pickup: false,
            volume: null,
            weight: null,
            goods: null,
            forms: [],
            parent_uuid: null,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'language']),
        languages() {
            return this.listLanguages
        },
    },
    async mounted() {
        await this.getTaskTypes()
        await this.getTaskStatuses()
        await this.checkCreate()
    },
    watch: {
        companySearching: debounce(function (val) {
            if (val && !this.company) {
                this.getCompanies(val)
            }
        }, 500),
        uvedSearching: debounce(function (val) {
            if (val && !this.uved) {
                this.getUveds(val)
            }
        }, 500),
        dealSearching: debounce(function (val) {
            if (val) {
                this.getDeals(val)
            }
        }, 500),
        responsibleSearching: debounce(function (val) {
            if (val) {
                this.getResponsibles(val)
            }
        }, 500),
        auditorSearching: debounce(function (val) {
            if (val) {
                this.getAuditors(val)
            }
        }, 500),
        alignerSearching: debounce(function (val) {
            if (val) {
                this.getAligners(val)
            }
        }, 500),
    },
    methods: {
        checkTermsDelivery() {
            if (this.terms_delivery.toUpperCase().includes('EXW')) {
                this.show_place_argo_pickup = true;
            } else {
                this.show_place_argo_pickup = false
            }
        },
        async checkCreate() {
            this.heading = this.$t('task_creation')
            if (this.$route.params.id) {
                this.parent_uuid = this.$route.params.id
            }
        },

        async getTaskType() {
            if (this.task_type === 3) {
                this.loading_type = null;
                this.type_transport = null;
                this.from_city = null;
                this.to_city = null;
                this.special_text = null;
                this.dangerous_cargo = null;
                this.stackable_cargo = null;
                this.terms_delivery = null;
                this.place_argo_pickup = null;
                this.weight = null;
                this.volume = null;
                this.insurance = null;


                if (this.loadingTypeItems.length <= 0) {
                    this.getLoadingType()
                }
                if (this.typeTransportItems.length <= 0) {
                    this.getTypeTransport()
                }
            } else {
                await this.getGroups()
            }
        },
        async getGroups() {

            this.loading = true;
            let params = {};

            params.type_form = 'groups';
            params.entity_type = 'task';
            params.sub_entity_type = 'task_type';
            params.sub_entity_id = this.task_type;

            if (this.task_uuid) {
                params.entity_uuid = this.task_uuid;
            }
            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.forms = res.body.data;
                    } else {
                        this.forms = [];
                    }
                })
                .catch(err => {
                    this.forms = [];
                    this.$toastr.error(this.$t('failed_to_get_list_groups'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getLoadingType() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/loading_type", {
                    params: params,
                })
                .then(res => {
                    this.loadingTypeItems = res.body.data
                })
                .catch(err => {
                    this.loadingTypeItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getTypeTransport() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/type_transport", {
                    params: params,
                })
                .then(res => {
                    this.typeTransportItems = res.body.data
                })
                .catch(err => {
                    this.typeTransportItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        closeDialogAdd() {
            if (window.history.length > 2) {
                this.$router.back()
            } else {
                this.$router.push({
                    name: 'task',
                })
            }


        },
        async getUved(uved_uuid) {
            if (uved_uuid) {
                this.loadingUveds = true
                await this.$http
                    .get("admin/uved/" + uved_uuid)
                    .then(res => {
                        this.uved = res.body.data.id;
                        this.uvedItems = [res.body.data]
                        this.company = res.body.data.company.id;
                        this.companyItems = [res.body.data.company]
                    })
                    .catch(err => {
                        this.uvedItems = []
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                    })
            }
        },
        showTask(uuid) {
            this.$router.push({
                name: 'task.show',
                params: {
                    id: uuid
                }
            })
        },
        deleteAccompanyingDocumentFile(item) {
            let index = this.task_document_urls.indexOf(item)
            this.task_document_urls.splice(index, 1)
        },
        deleteDocumentFile(item) {
            let index = this.task_documents_preview.indexOf(item)
            this.task_documents_preview.splice(index, 1)
            if (this.task_documents_preview.length === 0) {
                this.task_documents_dialog = false;
                this.task_document_urls = [];
                this.task_documents = []
            }
        },

        async addTaskFile() {
            if (this.task_documents_preview && this.task_documents_preview.length > 0) {
                for (let i in this.task_documents_preview) {
                    this.task_document_urls.push(this.task_documents_preview[i])
                }
                this.task_documents_dialog = false;
                this.task_documents_preview = [];
                this.task_documents = []
            }


        },
        async saveDealFile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.task_documents_preview && this.task_documents_preview.length > 0) {
                let is_image = false;
                for (let i in this.task_documents_preview) {
                    is_image = this.mimeTypeImage(this.task_documents_preview[i].type);
                    formData.append(`type_mime[${i}]`, this.task_documents_preview[i].type)
                    formData.append(`size[${i}]`, this.task_documents_preview[i].size)
                    formData.append(`file_name[${i}]`, this.task_documents_preview[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    this.task_documents_preview[i].blob = await this.convertBlobToBase64(this.task_documents_preview[i].blob)
                    if (this.task_documents_preview[i].blob) {
                        let blob = this.dataURL64toBlob(this.task_documents_preview[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.task_documents_preview[i].type)
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.task_documents_preview[i].blob)
                }
            }

            await this.$http
                .put(`admin/deal/file/${this.deal_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))

                    if (this.task_documents_preview && this.task_documents_preview.length > 0) {
                        for (let i in this.task_documents_preview) {
                            this.task_document_urls.push(this.task_documents_preview[i])
                        }
                        this.task_documents_dialog = false;
                        this.task_documents_preview = [];
                        this.task_documents = []
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('file_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async deleteFileServer(item) {
            if (confirm(this.$t('delete_deal_file'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/deal/file/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('deal_file_has_been_deleted'))
                        this.deleteAccompanyingDocumentFile(item)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_file_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        accompanyingDocumentsPreviewFiles() {
            let i = 0;
            let data = {}
            this.task_documents.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.task_documents_preview.push(data)
                i++
            })
            this.task_documents_dialog = true
        },


        dropFile: function (event) {
            // dropFile(event){
            event.stopPropagation();
            event.preventDefault();
            this.task_documents = Array.from(event.dataTransfer.files);
            this.accompanyingDocumentsPreviewFiles();
            this.dragover = false

            // event.dataTransfer.dropEffect = 'copy';
            // event.stopPropagation();
            // event.preventDefault();
            //
            // console.log(event.dataTransfer);
            // console.log(Array.from(event.dataTransfer));
            // console.log(event);
            // setTimeout(() => {
            //     this.task_documents = Array.from(event.dataTransfer.files);
            //     this.accompanyingDocumentsPreviewFiles();
            //     this.dragover = false
            // }, 100);

        },
        clearCompanies() {
            if (!this.company) {
                this.companyItems = []
            }
        },
        redirectToCompany(val) {
            if (val) {
                this.$router.push({
                    name: 'company.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getCompanies(str) {
            if (str) {
                this.loadingCompanies = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.company = str
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companyItems = res.body.data
                    })
                    .catch(err => {
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingCompanies = false
                    })
            }
        },

        setCompanyUVED(item) {
            if (item.company && item.company.id) {
                this.companyItems = [item.company];
                this.company = item.company.id;
            } else {
                this.companyItems = []
                this.company = null
            }
        },
        clearUveds() {
            if (!this.uved) {
                this.uvedItems = []
            }
        },
        redirectToUved(val) {
            if (val) {
                this.$router.push({
                    name: 'uved.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getUveds(str) {
            if (str) {
                this.loadingUveds = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.uved = str
                }
                await this.$http
                    .get("admin/uved", {
                        params: params,
                    })
                    .then(res => {
                        this.uvedItems = res.body.data
                    })
                    .catch(err => {
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                    })
            }
        },

        clearDeals() {
            if (!this.deal) {
                this.dealItems = []
            }
        },
        clearEmptyDealItems(items) {
            if (items && items.length > 0) {
                this.deal = items.filter(item => typeof item === 'object')
            }
        },
        async getDeals(str) {
            if (str) {
                this.loadingDeals = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.deal = str
                }
                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealItems = res.body.data
                    })
                    .catch(err => {
                        this.dealItems = []
                    })
                    .finally(end => {
                        this.loadingDeals = false
                    })
            }
        },

        clearResponsibles() {
            if (!this.responsible) {
                this.responsibleItems = []
            }
        },
        clearEmptyResponsibleItems(items) {
            if (items && items.length > 0) {
                this.responsible = items.filter(item => typeof item === 'object')
            }
        },
        async getResponsibles(str) {
            if (str) {
                this.loadingResponsibles = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.responsibleItems = res.body.data
                    })
                    .catch(err => {
                        this.responsibleItems = []
                    })
                    .finally(end => {
                        this.loadingResponsibles = false
                    })
            }
        },
        clearAuditors() {
            if (!this.auditor) {
                this.auditorItems = []
            }
        },
        clearEmptyAuditorItems(items) {
            if (items && items.length > 0) {
                this.auditor = items.filter(item => typeof item === 'object')
            }
        },
        async getAuditors(str) {
            if (str) {
                this.loadingAuditors = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.auditorItems = res.body.data
                    })
                    .catch(err => {
                        this.auditorItems = []
                    })
                    .finally(end => {
                        this.loadingAuditors = false
                    })
            }
        },
        clearAligners() {
            if (!this.aligner) {
                this.alignerItems = []
            }
        },
        clearEmptyAlignerItems(items) {
            if (items && items.length > 0) {
                this.aligner = items.filter(item => typeof item === 'object')
            }
        },
        async getAligners(str) {
            if (str) {
                this.loadingAligners = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.alignerItems = res.body.data
                    })
                    .catch(err => {
                        this.alignerItems = []
                    })
                    .finally(end => {
                        this.loadingAligners = false
                    })
            }
        },
        async getTaskStatuses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/task_status", {
                    params: params,
                })
                .then(res => {
                    this.task_statusItems = res.body.data
                })
                .catch(err => {
                    this.task_statusItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getTaskTypes() {

            this.loadingTaskTypes = true
            let params = {}
            await this.$http
                .get("admin/task_type", {
                    params: params,
                })
                .then(res => {
                    this.taskTypeItems = res.body.data
                })
                .catch(err => {
                    this.taskTypeItems = []
                })
                .finally(end => {
                    this.loadingTaskTypes = false
                })

        },
        async getTask() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/task/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.task_uuid = res.body.data.uuid
                    this.is_urgent = res.body.data.is_urgent
                    this.title = res.body.data.title
                    this.text = res.body.data.text
                    this.task_status = res.body.data.task_status.id
                    this.task_type = res.body.data.task_type.id
                    this.responsible = res.body.data.responsibles
                    this.auditor = res.body.data.auditors
                    this.aligner = res.body.data.aligners_edit
                    this.company = res.body.data.company.id
                    this.companyItems = [res.body.data.company]
                    this.uved = res.body.data.uved.id
                    this.uvedItems = [res.body.data.uved]
                    this.deal = res.body.data.deal
                    this.dealItems = res.body.data.deal
                    this.deadline = (res.body.data && res.body.data.deadline) ? this.$moment(res.body.data.deadline).format('YYYY-MM-DD HH:mm') : null
                    this.task_document_urls = res.body.data.documents
                    this.loading_type = res.body.data.task_bet_field.loading_type
                    this.type_transport = res.body.data.type_transport
                    this.from_city = res.body.data.task_bet_field.from_city
                    this.to_city = res.body.data.task_bet_field.to_city
                    this.special_text = res.body.data.task_bet_field.special_text
                    this.dangerous_cargo = res.body.data.task_bet_field.dangerous_cargo
                    this.stackable_cargo = res.body.data.task_bet_field.stackable_cargo
                    this.terms_delivery = res.body.data.task_bet_field.terms_delivery
                    this.place_argo_pickup = res.body.data.task_bet_field.place_argo_pickup
                    this.weight = res.body.data.task_bet_field.weight
                    this.volume = res.body.data.task_bet_field.volume
                    this.goods = res.body.data.task_bet_field.goods
                    this.insurance = res.body.data.task_bet_field.insurance

                    if (this.terms_delivery.toUpperCase().includes('EXW')) {
                        this.show_place_argo_pickup = true;
                    } else {
                        this.show_place_argo_pickup = false
                    }


                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_task'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async   saveTask() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            let add_task_type = 0;

            this.forms.reduce((acc, item) => {
                const fields = item.fields;
                const group_id = item.id
                for (let fieldName in fields) {
                    if (Object.hasOwn(fields, fieldName)) {
                        formData.append(`fields[${group_id}][${fieldName}]`, fields[fieldName].default)
                    }
                }
            }, []);

            if (this.parent_uuid) {
                formData.append('parent_uuid', this.parent_uuid)
            }

            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.task_type) {
                if (this.task_type.id) {
                    add_task_type = this.task_type.id;
                    formData.append('task_type', this.task_type.id)
                } else {
                    add_task_type = this.task_type;
                    formData.append('task_type', this.task_type)
                }
            }
            if (add_task_type === 3) {
                formData.append('title', this.from_city + ' - ' + this.to_city)
            }

            if (this.title) {
                formData.append('title', this.title)
            }
            if (this.text) {
                formData.append('text', this.text)
            }
            if (this.loading_type) {
                if (this.loading_type.id) {
                    formData.append('loading_type', this.loading_type.id)
                } else {
                    formData.append('loading_type', this.loading_type)
                }
            }
            if (this.type_transport) {
                formData.append('type_transport', this.type_transport)
            }
            if (this.from_city) {
                formData.append('from_city', this.from_city)
            }
            if (this.to_city) {
                formData.append('to_city', this.to_city)
            }
            if (this.special_text) {
                formData.append('special_text', this.special_text)
            }
            if (this.dangerous_cargo) {
                formData.append('dangerous_cargo', this.dangerous_cargo)
            }

            if (this.stackable_cargo) {
                formData.append('stackable_cargo', this.stackable_cargo)
            }

            if (this.terms_delivery) {
                formData.append('terms_delivery', this.terms_delivery)
            }
            if (this.place_argo_pickup) {
                formData.append('place_argo_pickup', this.place_argo_pickup)
            }
            if (this.weight) {
                formData.append('weight', this.weight)
            }
            if (this.volume) {
                formData.append('volume', this.volume)
            }
            if (this.goods) {
                formData.append('goods', this.goods)
            }
            if (this.insurance) {
                formData.append('insurance', this.insurance)
            }


            if (this.is_urgent) {
                if (this.is_urgent.id) {
                    formData.append('is_urgent', this.is_urgent.id)
                } else {
                    formData.append('is_urgent', this.is_urgent)
                }
            }

            if (this.deadline) {
                formData.append('deadline', this.deadline)
            }


            if (this.company) {
                if (this.company.id) {
                    formData.append('company', this.company.id)
                } else {
                    formData.append('company', this.company)
                }
            }

            if (this.uved) {
                if (this.uved.id) {
                    formData.append('uved', this.uved.id)
                } else {
                    formData.append('uved', this.uved)
                }
            }

            // if (this.deal) {
            //     if (this.deal.id) {
            //         formData.append('deal', this.deal.id)
            //     } else {
            //         formData.append('deal', this.deal)
            //     }
            // }

            if (this.deal && this.deal.length > 0) {
                for (let i in this.deal) {
                    if (this.deal[i].id !== undefined && this.deal[i].id > 0) {
                        formData.append(`deals[${i}]`, this.deal[i].id)
                    }
                }
            }

            if (this.task_status) {
                if (this.task_status.id) {
                    formData.append('task_status', this.task_status.id)
                } else {
                    formData.append('task_status', this.task_status)
                }
            }

            if (this.responsible && this.responsible.length > 0) {
                for (let i in this.responsible) {
                    if (this.responsible[i].id !== undefined && this.responsible[i].id > 0) {
                        formData.append(`responsibles[${i}]`, this.responsible[i].id)
                    }
                }
            }

            if (this.auditor && this.auditor.length > 0) {
                for (let i in this.auditor) {
                    if (this.auditor[i].id !== undefined && this.auditor[i].id > 0) {
                        formData.append(`auditors[${i}]`, this.auditor[i].id)
                    }
                }
            }

            if (this.aligner && this.aligner.length > 0) {
                for (let i in this.aligner) {
                    if (this.aligner[i].id !== undefined && this.aligner[i].id > 0) {
                        formData.append(`aligners[${i}]`, this.aligner[i].id)
                    }
                }
            }


            if (this.task_document_urls && this.task_document_urls.length > 0) {
                let is_image = false;
                for (let i in this.task_document_urls) {
                    is_image = this.mimeTypeImage(this.task_document_urls[i].type);
                    formData.append(`type_mime[${i}]`, this.task_document_urls[i].type)
                    formData.append(`size[${i}]`, this.task_document_urls[i].size)
                    formData.append(`file_name[${i}]`, this.task_document_urls[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    if (this.task_document_urls[i].blob) {
                        this.task_document_urls[i].blob = await this.convertBlobToBase64(this.task_document_urls[i].blob)
                        let blob = this.dataURL64toBlob(this.task_document_urls[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.task_document_urls[i].type)
                        }
                    }
                }
            }


            if (this.$route.params.id && this.task_uuid) {
                await this.$http
                    .put(`admin/task/${this.task_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('task_has_been_updated'))
                        this.$router.push({
                            name: 'task',
                        })
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/task', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('task_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.uuid) {
                            this.showTask(res.body.data.uuid)
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async getDeal(deal_uuid) {
            if (deal_uuid) {
                this.loadingDeal = true
                await this.$http
                    .get("admin/deal/" + deal_uuid)
                    .then(res => {
                        this.company = res.body.data.company.id
                        this.companyItems = [res.body.data.company]
                        this.responsible = res.body.data.responsibles
                        this.auditor = res.body.data.auditors
                        this.deal = res.body.data.id
                        this.dealItems = [res.body.data]
                        this.uved = res.body.data.uved.id
                        this.uvedItems = [res.body.data.uved]
                    })
                    .catch(err => {
                        this.dealItems = []
                        this.uvedItems = []
                        this.responsible = []
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingDeal = false
                    })
            }
        },

    }
}
</script>

<style scoped>
.v-card--link:before {
    background: none;
}

>>> .heightTable td {
    height: 50px !important;
}

.task_number {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    color: #777777;
}

.text-label {
    display: block;
    color: #a9a9a9;
    font-size: 10px;
}

.wrapper-progressBar {
    width: 100%
}

.progressBar {
}

.progressBar li {
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    text-align: center;
}

.progressBar li:before {
    content: " ";
    line-height: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white;

}

.progressBar li:after {
    content: "";
    position: absolute;
    width: 90%;
    height: 4px;
    background-color: #ddd;
    top: 3px;
    left: -45%;

}

.progressBar li:first-child:after {
    content: none;

}

.progressBar li.active {
    color: #265898;

}

.progressBar li.active:before {
    border-color: #265898;
    background-color: #265898;

}

.progressBar .active:after {
    background-color: #265898;
}

.color_black {
    color: #000;
}

.color_dodgerblue {
    color: #265898;
}

</style>
